import addMeta from '@/router/addMeta';

const children = [
  {
    path: '',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: 'error-404',
    name: 'error-404',
    component: () => import('@/pages/Error404.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/pages/Error404.vue') },
];
const routes = {
  path: '/',
  name: 'base',
  redirect: '/',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'base'),
};

export default routes;
export { children };
