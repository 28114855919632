import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import pinia from '@/stores';

import '@/styles/init/index.scss';
import '@/styles/utils/_rwd.scss';
import 'v-calendar/dist/style.css';
import { WhiteSheet } from '@highwealth/lib';
import VueClickAway from 'vue3-click-away';
import '@highwealth/lib/dist/style.css';
const app = createApp(App);

app.component('WhiteSheet', WhiteSheet);
app.use(pinia);
app.use(VueClickAway);
app.use(router);
app.mount('#app');
