import { createRouter, createWebHistory } from 'vue-router';
import base from '@/router/pages/base';
import survey from '@/router/pages/survey';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    base,
    survey,

  ],
});

router.beforeEach((to, from, next) => {
  const scrollEle = document.querySelector('.ly-base-layout__body__container__content');

  if (scrollEle) {
    scrollEle.scrollTo({
      top: 0,
      left: 0,
    });
  }

  next();
});

export default router;
