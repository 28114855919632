import addMeta from '@/router/addMeta';

const children = [
  {
    path: 'info',
    name: 'survey-info',
    component: () => import('@/pages/survey/Info.vue'),
  },
  {
    path: 'client',
    name: 'survey-client',
    component: () => import('@/pages/survey/Client.vue'),
  },
  {
    path: 'sales',
    name: 'survey-sales',
    component: () => import('@/pages/survey/Sales.vue'),
  },
];
const routes = {
  path: '/survey',
  name: 'survey',
  redirect: '/survey-info',
  component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
  children: addMeta(children, 'category', 'survey'),
};

export default routes;
export { children };
